import { Injectable, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { distinctUntilKeyChanged, filter, switchMap, takeUntil } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class ConfigService implements OnDestroy {
  public pagination: any = {};
  public dashboard = new BehaviorSubject(null);

  private destroyed = new Subject();

  constructor(private firestore: AngularFirestore, private authService: AuthService) {
    this.authService.user
      .pipe(
        takeUntil(this.destroyed),
        filter((user) => !!user),
        distinctUntilKeyChanged('uid'),
        switchMap(() =>
          combineLatest([
            this.firestore.collection('config').doc('pagination').valueChanges(),
            this.firestore.collection('config').doc('dashboard').valueChanges(),
          ]),
        ),
      )
      .subscribe(([pagination, dashboard]) => {
        this.pagination = pagination || {};
        this.dashboard.next(dashboard);
      });
  }

  getPagination(entity: string, userSpecific = true): number {
    const userData = this.authService.userData.value;
    if (!userData) {
      return 0;
    }
    if (userSpecific) {
      return userData.role === 'ADMIN'
        ? this.pagination[`${entity}`] || 0
        : userData[`${entity}`] || 0;
    }
    return this.pagination[`${entity}`] || 0;
  }

  ngOnDestroy() {
    this.destroyed.next(undefined);
    this.destroyed.complete();
  }
}
